import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import style from './header.module.scss';
import { Container } from './container';
import { Button } from './button';
import { InfoIcon } from './icons/info';
import { UserContext } from '../context/UserContext';
import { API_ENDPOINT } from '../api';

const HeaderImage = () => (
  <StaticQuery
    query={graphql`
      query {
        mainLogo: file(relativePath: { eq: "IBA-HD_Logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Link to="/">
          <Img
            fluid={data.mainLogo.childImageSharp.fluid}
            className={style.image}
            loading="eager"
            placeholderClassName={style.imagePlaceholder}
            fadeIn={false}
            style={{ marginTop: '8px' }}
          />
        </Link>
      </>
    )}
  />
);

const Header = ({ siteTitle }) => (
  <header className={style.header}>
    <Container>
      <HeaderImage />
      <div className={style.title}>
        <h1>Patrick-Henry-Village_mitsprechen</h1>
      </div>
      <div className={style.pullRight}>
        <UserContext.Consumer>
          {user =>
            user.loggedIn &&
            user.roles.includes('ROLE_ADMIN') && (
              <a href={`${API_ENDPOINT}/api/export/csv`} download>
                <Button modifier={'light'}>Export CSV</Button>
              </a>
            )
          }
        </UserContext.Consumer>
        <Link to="/">
          <Button
            modifier={'light'}
            icon={<InfoIcon style={{ marginTop: '0', height: '20px', width: '20px' }} />}
            style={{ padding: '7px 18px 0', marginTop: '5px' }}
          >
            Startseite
          </Button>
        </Link>
        <Link to="/map/einbindung/regionalemobilitaet/posts">
          <Button
            modifier="light"
            style={{ padding: '7px 18px 0', marginTop: '5px' }}
            icon={
              <img
                src={require('../images/speech-bubbles.png')}
                alt="Sprechblasen"
                style={{
                  margin: '3px 13px 0 0',
                  float: 'left',
                  height: '15px'
                }}
              />
            }
          >
            Mitsprechen
          </Button>
        </Link>
      </div>
    </Container>
  </header>
);

export default Header;
