import React from 'react';
import style from './footer.module.scss';
import { Link } from 'gatsby';

export const Footer = ({ fixed = true }) => {
  let className = style.footer;
  if (!fixed) {
    className += ' ' + style.notFixed;
  }

  return (
    <footer className={className}>
      <div className={style.left}>
        <Link to="/imprint">Impressum und Datenschutz</Link> | <Link to="/">Infos</Link> |{' '}
        <Link to="/feedback">Kontakt</Link>
      </div>
      <div className={style.right}>{new Date().getFullYear()} © PLAN:KOOPERATIV</div>
    </footer>
  );
};
